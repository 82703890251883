import React from 'react';
import { Motion, spring } from 'react-motion';

class SvgPaths extends React.Component {
  state = {
    cp1: 100,
    cp2: 300,
  }

  handleMouseEnter = () => {
    this.setState({
      cp1: 300,
      cp2: 100,
    });
  }

  handleMouseLeave = () => {
    this.setState({
      cp1: 100,
      cp2: 300,
    });
  }

  render() {
    return (
      <div
        className="image"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Motion
          defaultStyle={{
            cp1: 100,
            cp2: 300,
          }}
          style={{
            cp1: spring(this.state.cp1),
            cp2: spring(this.state.cp2),
          }}
        >
          {({ cp1, cp2 }) => (
            <svg
              viewBox="0 0 600 400"
              width="600px"
              height="400px"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >

              <line
                x1="100"
                y1="200"
                x2="200"
                y2={cp1}
                stroke="var(--brand-color)"
                strokeWidth="4px"
                strokeLinecap="round"
                strokeDasharray="10px"
              />
              <line
                x1="300"
                y1="200"
                x2="200"
                y2={cp1}
                stroke="var(--brand-color)"
                strokeWidth="4px"
                strokeLinecap="round"
                strokeDasharray="10px"
              />
              <g transform={`translate(200,${cp1})`} style={{ cursor: 'pointer' }}>
                <circle cx="0" cy="0" r="16" stroke="var(--heading-color)" fill="none" strokeWidth="5px" />
                <text
                  x="30"
                  y="-20"
                  fill="var(--heading-color)"
                  fontSize="20"
                  textAnchor="left"
                  stroke="none"
                  style={{ fontFamily: '"Consolas", monospace' }}
                >
                  200,{Math.round(cp1)}
                </text>
              </g>

              <line
                x1="500"
                y1="200"
                x2="400"
                y2={cp2}
                stroke="var(--brand-color)"
                strokeWidth="4px"
                strokeLinecap="round"
                strokeDasharray="10px"
              />
              <line
                x1="300"
                y1="200"
                x2="400"
                y2={cp2}
                stroke="var(--brand-color)"
                strokeWidth="4px"
                strokeLinecap="round"
                strokeDasharray="10px"
              />
              <g transform={`translate(400,${cp2})`} style={{ cursor: 'pointer' }}>
                <circle cx="0" cy="0" r="16" stroke="var(--heading-color)" fill="none" strokeWidth="5px" />
                <text
                  x="-120"
                  y="20"
                  fill="var(--heading-color)"
                  fontSize="20"
                  textAnchor="left"
                  stroke="none"
                  style={{
                    fontFamily: '"Consolas", monospace',
                  }}
                >
                  400,{Math.round(cp2)}
                </text>
              </g>

              <path
                d={`M100,200 Q 200,${cp1} 300,200 T 500,200`}
                stroke="var(--heading-color)"
                strokeWidth="15px"
                strokeLinecap="round"
                fill="none"
              />

              <circle cx="100" cy="200" r="6" fill="var(--slight)" />
              <circle cx="300" cy="200" r="6" fill="var(--slight)" />
              <circle cx="500" cy="200" r="6" fill="var(--slight)" />
            </svg>
          )
          }
        </Motion>
      </div>
    );
  }
}

export default SvgPaths;
