import React from 'react';
import PropTypes from 'prop-types';
import Handle from './draggable';
import { rhythm } from '../../utils/typography';

class ArcExample extends React.Component {
  state = {
    c1x: 250,
    c1y: 250,
    c2x: 550,
    c2y: 550,
    rx: 250,
    ry: 250,
    axisRotation: 0,
    largeArcFlag: false,
    sweepFlag: false,
    dragging1: false,
    dragging2: false,
    draggingRadius: false,
  }

  setControlPoint1 = (c1x, c1y) => {
    this.setState({
      c1x,
      c1y,
    });
  }

  setDragging1 = (value) => {
    this.setState({
      dragging1: value,
    });
  }

  setControlPoint2 = (c2x, c2y) => {
    this.setState({
      c2x,
      c2y,
    });
  }

  setDragging2 = (value) => {
    this.setState({
      dragging2: value,
    });
  }

  setRadius = (rx, ry) => {
    this.setState({
      rx,
      ry,
    });
  }

  setDraggingRadius = (value) => {
    this.setState({
      draggingRadius: value,
    });
  }

  // dragReset = () => {
  //   if (this.state.dragging) {
  //     this.dragEnd();
  //   }
  // }

  changeValue = (event) => {
    const name = event.currentTarget.name;
    this.setState({
      [name]: event.currentTarget.value,
    });
  }

  changeFlag = (event) => {
    const name = event.currentTarget.name;
    const newValue = !this.state[name];
    this.setState({
      [name]: newValue,
    });
  }

  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: `0 auto ${rhythm(1)} auto` }}>
        <div>
          <div><code>X Radius: {this.state.rx}</code></div>
          <input onChange={this.changeValue} value={this.state.rx} type="range" name="rx" min="1" max="300" />
          <div><code>Y Radius: {this.state.ry}</code></div>
          <input onChange={this.changeValue} value={this.state.ry} type="range" name="ry" min="1" max="300" />
          <div><code>Axis rotation: {this.state.axisRotation}</code></div>
          <input
            onChange={this.changeValue}
            value={this.state.axisRotation}
            type="range"
            name="axisRotation"
            min="-180"
            max="180"
          />
          <div>
            <label htmlFor="largeArcFlag">
              <input onChange={this.changeFlag} value={this.state.largeArcFlag} type="checkbox" name="largeArcFlag" id="largeArcFlag" />
              <code>Large arc flag</code>
            </label>
          </div>
          <div>
            <label htmlFor="sweepFlag">
              <input onChange={this.changeFlag} value={this.state.sweepFlag} type="checkbox" name="sweepFlag" id="sweepFlag" />
              <code>Sweep flag</code>
            </label>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <svg
            viewBox="0 0 800 800"
            width="460px"
            height="460px"
            ref={(svg) => { this.svg = svg; }}
            className="svg-example"
            style={{
              display: 'block',
              maxWidth: '100%',
              height: 'auto',
              touchAction: 'none'
            }}
          >
            <defs>
              <marker
                id="markerArrow"
                markerWidth="5"
                markerHeight="6"
                refX="1"
                refY="3"
                orient="auto"
              >
                <path d="M0,0 L5,3 L0,6 Z" className="svg-example-marker" />
              </marker>
            </defs>
            { this.props.grid &&
              <g>
                <line
                  className="svg-example-line-fat"
                  x1="200"
                  y1="0"
                  x2="200"
                  y2="800"
                />
                <line
                  className="svg-example-line-fat"
                  x1="400"
                  y1="0"
                  x2="400"
                  y2="800"
                />
                <line
                  className="svg-example-line-fat"
                  x1="600"
                  y1="0"
                  x2="600"
                  y2="800"
                />
                <line
                  className="svg-example-line-fat"
                  x1="0"
                  y1="200"
                  x2="800"
                  y2="200"
                />
                <line
                  className="svg-example-line-fat"
                  x1="0"
                  y1="400"
                  x2="800"
                  y2="400"
                />
                <line
                  className="svg-example-line-fat"
                  x1="0"
                  y1="600"
                  x2="800"
                  y2="600"
                />
              </g>
            }

            {/* Guides */}
            <path
              d={`M ${this.state.c1x},${this.state.c1y}
                A ${this.state.rx},${this.state.ry}
                ${this.state.axisRotation} 0,0
                ${this.state.c2x},${this.state.c2y}
              `}
              className="svg-example-arc-guide"
            />
            <path
              d={`M ${this.state.c1x},${this.state.c1y}
                A ${this.state.rx},${this.state.ry}
                ${this.state.axisRotation} 1,0
                ${this.state.c2x},${this.state.c2y}
              `}
              className="svg-example-arc-guide"
            />
            <path
              d={`M ${this.state.c1x},${this.state.c1y}
                A ${this.state.rx},${this.state.ry}
                ${this.state.axisRotation} 0,1
                ${this.state.c2x},${this.state.c2y}
              `}
              className="svg-example-arc-guide"
            />
            <path
              d={`M ${this.state.c1x},${this.state.c1y}
                A ${this.state.rx},${this.state.ry}
                ${this.state.axisRotation} 1,1
                ${this.state.c2x},${this.state.c2y}
              `}
              className="svg-example-arc-guide"
            />

            <path
              d={`M ${this.state.c1x},${this.state.c1y}
                A ${this.state.rx},${this.state.ry}
                ${this.state.axisRotation} ${this.state.largeArcFlag ? '1' : '0'},${this.state.sweepFlag ? '1' : '0'}
                ${this.state.c2x},${this.state.c2y}
              `}
              className="svg-example-arc"
              // style={{
              //   markerEnd: 'url(#markerArrow)',
              // }}
            />
            <Handle
              x={this.state.c1x}
              y={this.state.c1y}
              dragging={this.state.dragging1}
              setDragging={this.setDragging1}
              setCoordinates={this.setControlPoint1}
              svg={this.svg}
              stroke
            />

            <Handle
              x={this.state.c2x}
              y={this.state.c2y}
              dragging={this.state.dragging2}
              setDragging={this.setDragging2}
              setCoordinates={this.setControlPoint2}
              svg={this.svg}
              stroke
            />

            {/* <Handle
              x={this.state.rx}
              y={this.state.ry}
              dragging={this.state.draggingRadius}
              setDragging={this.setDraggingRadius}
              setCoordinates={this.setRadius}
              svg={this.svg}
            /> */}
          </svg>
          <div className="svg-drag-example-text">
            {'<path d="'}
            <strong>
              {`M ${this.state.c1x} ${this.state.c1y}
A ${this.state.rx} ${this.state.ry}
${this.state.axisRotation} ${this.state.largeArcFlag ? '1' : '0'} ${this.state.sweepFlag ? '1' : '0'}
${this.state.c2x} ${this.state.c2y}
              `}</strong>{'" />'}
          </div>
        </div>
      </div>
    );
  }
}

ArcExample.propTypes = {
  grid: PropTypes.bool,
};

ArcExample.defaultProps = {
  grid: false,
};

export default ArcExample;
