import React from 'react';
import { rhythm } from '../../utils/typography';

class StrokeExample extends React.Component {
  state = {
    width: 1,
    dasharray: 0,
    dashoffset: 0,
    linecap: 'round',
    linejoin: 'round',
  }

  changeValue = (event) => {
    const name = event.currentTarget.name;
    this.setState({
      [name]: event.currentTarget.value,
    });
  }

  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', margin: `0 auto ${rhythm(1)} auto` }}>
        <div style={{ minWidth: '50%' }}>
          <div><code>stroke-width: {this.state.width}</code></div>
          <input onChange={this.changeValue} value={this.state.width} type="range" name="width" min="0" max="10" />
          <div><code>stroke-dasharray: {this.state.dasharray}</code></div>
          <input onChange={this.changeValue} value={this.state.dasharray} type="range" name="dasharray" min="0" max="100" />
          <div><code>stroke-dashoffset: {this.state.dashoffset}</code></div>
          <input onChange={this.changeValue} value={this.state.dashoffset} type="range" name="dashoffset" min="0" max="100" />
        </div>
        <div>
          <svg
            viewBox="0 0 100 100"
            width="400px"
            height="400px"
            style={{
              width: '100%',
            }}
          >
            <circle
              cx="50"
              cy="50"
              r="40"
              fill="none"
              style={{
                stroke: 'var(--text-color)',
                strokeWidth: this.state.width,
                strokeDasharray: this.state.dasharray,
                strokeDashoffset: this.state.dashoffset,
              }}
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default StrokeExample;
