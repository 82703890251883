import React from 'react';

class Draggable extends React.Component {
  dragStart = (event) => {
    this.coords = {
      x: event.pageX,
      y: event.pageY,
    };
    if (typeof document !== 'undefined') {
      document.addEventListener('mousemove', this.drag);
      document.addEventListener('touchmove', this.drag);
    }

    this.props.setDragging(true);
  }

  drag = (event) => {
    event.preventDefault();
    const point = this.props.svg.createSVGPoint();

    point.x = event.clientX || event.touches[0].clientX;
    point.y = event.clientY || event.touches[0].clientY;
    console.log('event', event);
    const svgP = point.matrixTransform(this.props.svg.getScreenCTM().inverse());

    const xCoord = Math.round(svgP.x);
    const yCoord = Math.round(svgP.y);

    this.props.setCoordinates(xCoord, yCoord);
  }

  dragEnd = () => {
    if (typeof document !== 'undefined') {
      document.removeEventListener('mousemove', this.drag);
      document.removeEventListener('touchmove', this.drag);
    }
    this.coords = {};
    this.props.setDragging(false);
  }

  render() {
    return (
      <g
        onMouseDown={this.dragStart}
        onTouchStart={this.dragStart}
        onMouseUp={this.dragEnd}
        onTouchEnd={this.dragEnd}
        transform={`translate(${this.props.x},${this.props.y})`}
        style={{
          cursor: 'pointer',
        }}
      >
        <circle
          cx="0"
          cy="0"
          r={this.props.dragging ? '20' : '16'}
          className="svg-example-handle"
        />
        <text x="20" y="-20" className="svg-example-text" fontSize="20" textAnchor="left" stroke="none">
          {`${this.props.x}, ${this.props.y}`}
        </text>
      </g>
    );
  }
}

export default Draggable;
