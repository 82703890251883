import React from 'react';
import PropTypes from 'prop-types';
import Handle from './draggable';
import { rhythm } from '../../utils/typography';

class CurveExample extends React.Component {
  state = {
    c1x: 200,
    c1y: 100,
    c2x: 300,
    c2y: 300,
    c3x: 600,
    c3y: 300,
    dragging1: false,
    dragging2: false,
  }

  setControlPoint1 = (c1x, c1y) => {
    this.setState({
      c1x,
      c1y,
    });
  }

  setDragging1 = (value) => {
    this.setState({
      dragging1: value,
    });
  }

  setControlPoint2 = (c2x, c2y) => {
    this.setState({
      c2x,
      c2y,
    });
  }

  setDragging2 = (value) => {
    this.setState({
      dragging2: value,
    });
  }

  setControlPoint3 = (c3x, c3y) => {
    this.setState({
      c3x,
      c3y,
    });
  }

  setDragging3 = (value) => {
    this.setState({
      dragging3: value,
    });
  }

  render() {
    return (
      <div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: `0 auto ${rhythm(1)} auto`
        }}>
          <svg
            viewBox="0 0 800 400"
            width="800px"
            height="400px"
            ref={(svg) => { this.svg = svg; }}
            className="svg-example"
            style={{
              display: 'block',
              maxWidth: '100%',
              height: 'auto',
              touchAction: 'none'
            }}
          >
            { this.props.grid &&
              <g>
                <line
                  className="svg-example-line-fat"
                  x1="-200"
                  y1="100"
                  x2="800"
                  y2="100"
                />
                <line
                  className="svg-example-line-fat"
                  x1="-200"
                  y1="200"
                  x2="800"
                  y2="200"
                />
                <line
                  className="svg-example-line-fat"
                  x1="-200"
                  y1="300"
                  x2="800"
                  y2="300"
                />
                {/* Vertical */}
                <line
                  className="svg-example-line-fat"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="400"
                />
                <line
                  className="svg-example-line-fat"
                  x1="100"
                  y1="0"
                  x2="100"
                  y2="400"
                />
                <line
                  className="svg-example-line-fat"
                  x1="200"
                  y1="0"
                  x2="200"
                  y2="400"
                />
                <line
                  className="svg-example-line-fat"
                  x1="300"
                  y1="0"
                  x2="300"
                  y2="400"
                />
                <line
                  className="svg-example-line-fat"
                  x1="400"
                  y1="0"
                  x2="400"
                  y2="400"
                />
                <line
                  className="svg-example-line-fat"
                  x1="500"
                  y1="0"
                  x2="500"
                  y2="400"
                />
                <line
                  className="svg-example-line-fat"
                  x1="600"
                  y1="0"
                  x2="600"
                  y2="400"
                />
                <line
                  className="svg-example-line-fat"
                  x1="700"
                  y1="0"
                  x2="700"
                  y2="400"
                />
              </g>
            }
            {/* Handle lines */}
            <line
              x1={this.state.c1x}
              y1={this.state.c1y}
              x2="100"
              y2="200"
              className="svg-example-guide"
            />
            {this.props.letter === 'C' &&
              <line
                x1={this.state.c2x}
                y1={this.state.c2y}
                x2="400"
                y2="200"
                className="svg-example-guide"
              />
            }
            {this.props.letter === 'Q' &&
              <line
                x1={this.state.c1x}
                y1={this.state.c1y}
                x2="400"
                y2="200"
                className="svg-example-guide"
              />
            }
            {this.props.double === 'S' &&
              <line
                x1={800 - this.state.c2x}
                y1={400 - this.state.c2y}
                x2="400"
                y2="200"
                className="svg-example-guide"
              />
            }
            {this.props.double === 'S' &&
              <line
                x1={this.state.c3x}
                y1={this.state.c3y}
                x2="700"
                y2="200"
                className="svg-example-guide"
              />
            }
            {/* Line */}
            <path
              d={`
                M100,200
                ${this.props.letter}
                ${this.state.c1x},${this.state.c1y}
                ${this.props.letter === 'C' ? `${this.state.c2x},${this.state.c2y}` : ''}
                400,200
                ${this.props.double}
                ${this.props.double === 'S' ? `${this.state.c3x},${this.state.c3y} 700,200` : '700,200'}
              `}
              className="svg-example-arc"
            />
            {/* Handles */}
            {this.props.double === 'S' &&
              <circle
                cx={800 - this.state.c2x}
                cy={400 - this.state.c2y}
                r="10"
                fill="var(--slight)"
              />
            }
            <Handle
              x={this.state.c1x}
              y={this.state.c1y}
              dragging={this.state.dragging1}
              setDragging={this.setDragging1}
              setCoordinates={this.setControlPoint1}
              svg={this.svg}
            />
            {this.props.letter === 'C' &&
              <Handle
                x={this.state.c2x}
                y={this.state.c2y}
                dragging={this.state.dragging2}
                setDragging={this.setDragging2}
                setCoordinates={this.setControlPoint2}
                svg={this.svg}
              />
            }
            {this.props.double === 'S' &&
              <Handle
                x={this.state.c3x}
                y={this.state.c3y}
                dragging={this.state.dragging3}
                setDragging={this.setDragging3}
                setCoordinates={this.setControlPoint3}
                svg={this.svg}
              />
            }
            {/* Points */}
            <circle cx="100" cy="200" r="6" className="svg-example-point" />
            <circle cx="400" cy="200" r="6" className="svg-example-point" />
            {this.props.double && <circle cx="700" cy="200" r="6" className="svg-example-point" />}
          </svg>
          <div className="svg-drag-example-text">
            {'<path d="'}
            <strong>
              {`M 100,200
  ${this.props.letter} ${this.state.c1x},${this.state.c1y} ${this.state.c2x},${this.state.c2y} 400,200
  ${this.props.double === 'S' ? `S ${this.state.c3x},${this.state.c3y} 700,200` : ''}
  ${this.props.double === 'T' ? 'T 700,200' : ''}`}</strong>{'" />'}
          </div>
        </div>
      </div>
    );
  }
}

CurveExample.propTypes = {
  letter: PropTypes.string,
  double: PropTypes.string,
  grid: PropTypes.bool,
};

CurveExample.defaultProps = {
  letter: 'C',
  double: undefined,
  grid: false,
};

export default CurveExample;
