import React from 'react';
import { StaggeredMotion, spring } from 'react-motion';

const moveFromTo = (pointA, pointB, percentage) => {
  // 425, 325, 0
  const distance = pointB - pointA;
  return pointA + (distance * percentage);
};

class Svg123 extends React.Component {
  state = {
    drawTriangle: 0,
    drawing: false,
  }

  handleMouseEnter = () => {
    this.setState({
      drawing: true,
      drawTriangle: 1,
    });
  }

  handleMouseLeave = () => {
    this.setState({
      drawing: false,
      drawTriangle: 0,
    });
  }

  render() {
    return (
      <div
        className="image"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <StaggeredMotion
          defaultStyles={[
            { drawTriangle: 0 },
            { drawTriangle: 0 },
            { drawTriangle: 0 },
            { drawTriangle: 0 },
          ]}
          styles={prevInterpolatedStyles => prevInterpolatedStyles.map((_, index) => {
            // Drawing in anim
            if (this.state.drawing) {
              if (index === 0) {
                return {
                  drawTriangle: spring(this.state.drawTriangle),
                };
              }
              if (prevInterpolatedStyles[index - 1].drawTriangle >= 1) {
                return {
                  drawTriangle: spring(this.state.drawTriangle),
                };
              }
              return {
                drawTriangle: 0,
              };
            }

            // Drawing out anim (?)
            if (index === 3) {
              return {
                drawTriangle: spring(this.state.drawTriangle),
              };
            }
            if (prevInterpolatedStyles[index + 1].drawTriangle <= 0) {
              return {
                drawTriangle: spring(this.state.drawTriangle),
              };
            }
            return {
              drawTriangle: 1,
            };
          })}
        >
          {(styles) => {
            let translatePen = '0,0';
            if (styles[3].drawTriangle > 0) {
              translatePen = `
                ${moveFromTo(0, 150, styles[3].drawTriangle)},
                ${moveFromTo(0, -80, styles[3].drawTriangle)}
              `;
            } else if (styles[2].drawTriangle > 0) {
              translatePen = `
                ${moveFromTo(50, 0, styles[2].drawTriangle)},
                ${moveFromTo(-75, 0, styles[2].drawTriangle)}
              `;
            } else if (styles[1].drawTriangle > 0) {
              translatePen = `
                ${moveFromTo(100, 50, styles[1].drawTriangle)},
                ${moveFromTo(0, -75, styles[1].drawTriangle)}
              `;
            } else if (styles[0].drawTriangle > 0) {
              translatePen = `${moveFromTo(0, 100, styles[0].drawTriangle)},0`;
            }
            return (
              <svg
                viewBox="0 0 600 400"
                width="600px"
                height="400px"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
              >
                <circle
                  cx="140"
                  cy="270"
                  r="80"
                  stroke="var(--text-color)"
                  strokeWidth="12px"
                  fill="none"
                />
                <rect
                  x="480"
                  y="200"
                  width="50"
                  height="150"
                  stroke="var(--text-color)"
                  strokeWidth="12px"
                  fill="none"
                  strokeLinecap="round"
                />
                <rect
                  x="250"
                  y="250"
                  width="200"
                  height="100"
                  stroke="var(--text-color)"
                  strokeWidth="12px"
                  fill="none"
                  strokeLinecap="round"
                />
                {/* Triangle */}
                <path
                  d={`
                    M 325,220
                    ${styles[0].drawTriangle > 0 ? `
                      ${moveFromTo(325, 425, styles[0].drawTriangle, true)},
                      220
                    ` : ''}
                    ${styles[1].drawTriangle > 0 ? `
                      ${moveFromTo(425, 375, styles[1].drawTriangle)},
                      ${moveFromTo(220, 140, styles[1].drawTriangle)},
                    ` : ''}
                    ${styles[2].drawTriangle > 0 ? `
                      ${moveFromTo(375, 325, styles[2].drawTriangle)},
                      ${moveFromTo(140, 220, styles[2].drawTriangle)},
                    ` : ''}
                    ${styles[3].drawTriangle > 0 ? 'Z' : ''}
                  `}
                  ref={(triangle) => { this.triangle = triangle; }}
                  stroke="var(--slight)"
                  strokeDasharray={this.state.triangleLength}
                  strokeLinecap="round"
                  strokeWidth="12px"
                  fill="none"
                />
                {/* Pen */}
                <g transform={`translate(${translatePen})`}>
                  <path
                    test="425,220"
                    d="M 545,-60 345,160 325,220 385,200 625,-60"
                    stroke="var(--text-color)"
                    strokeWidth="12px"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="var(--background-color)"
                  />
                  <path
                    d="M 345,160 365,160 365,180 385,180 385,200"
                    stroke="var(--text-color)"
                    strokeWidth="6px"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                  />
                </g>
              </svg>
            );
          }}
        </StaggeredMotion>
      </div>
    );
  }
}

export default Svg123;
