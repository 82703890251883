import React from 'react';
import PropTypes from 'prop-types';
import { rhythm } from '../../utils/typography';

const SvgExample = ({
  children,
  grid,
  width,
  viewbox,
  ...props
}) => (
  <div style={{ maxWidth: `${width}px`, margin: `0 auto ${rhythm(1)} auto` }} {...props}>
    <svg
      viewBox={viewbox}
    >
      {grid &&
        <g>
          <line
            className="svg-example-grid-line"
            x1="1"
            y1="0"
            x2="1"
            y2="10"
          />
          <line
            className="svg-example-grid-line"
            x1="2"
            y1="0"
            x2="2"
            y2="10"
          />
          <line
            className="svg-example-grid-line"
            x1="3"
            y1="0"
            x2="3"
            y2="10"
          />
          <line
            className="svg-example-grid-line"
            x1="4"
            y1="0"
            x2="4"
            y2="10"
          />
          <line
            className="svg-example-grid-line"
            x1="5"
            y1="0"
            x2="5"
            y2="10"
          />
          <line
            className="svg-example-grid-line"
            x1="6"
            y1="0"
            x2="6"
            y2="10"
          />
          <line
            className="svg-example-grid-line"
            x1="7"
            y1="0"
            x2="7"
            y2="10"
          />
          <line
            className="svg-example-grid-line"
            x1="8"
            y1="0"
            x2="8"
            y2="10"
          />
          <line
            className="svg-example-grid-line"
            x1="9"
            y1="0"
            x2="9"
            y2="10"
          />
          <line
            className="svg-example-grid-line"
            x1="0"
            y1="1"
            x2="10"
            y2="1"
          />
          <line
            className="svg-example-grid-line"
            x1="0"
            y1="2"
            x2="10"
            y2="2"
          />
          <line
            className="svg-example-grid-line"
            x1="0"
            y1="3"
            x2="10"
            y2="3"
          />
          <line
            className="svg-example-grid-line"
            x1="0"
            y1="4"
            x2="10"
            y2="4"
          />
          <line
            className="svg-example-grid-line"
            x1="0"
            y1="5"
            x2="10"
            y2="5"
          />
          <line
            className="svg-example-grid-line"
            x1="0"
            y1="6"
            x2="10"
            y2="6"
          />
          <line
            className="svg-example-grid-line"
            x1="0"
            y1="7"
            x2="10"
            y2="7"
          />
          <line
            className="svg-example-grid-line"
            x1="0"
            y1="8"
            x2="10"
            y2="8"
          />
          <line
            className="svg-example-grid-line"
            x1="0"
            y1="9"
            x2="10"
            y2="9"
          />
        </g>
      }

      {children}
    </svg>
  </div>
);

SvgExample.propTypes = {
  children: PropTypes.node.isRequired,
  grid: PropTypes.bool,
  width: PropTypes.number,
  viewbox: PropTypes.string,
};

SvgExample.defaultProps = {
  grid: false,
  width: 300,
  viewbox: '0 0 100 100',
};

export default SvgExample;
