import React from 'react';
import { Motion, spring } from 'react-motion';

class NormanDoors extends React.Component {
  state = {
    doorPoint1x: 350,
    doorPoint1y: 350,
    doorPoint2x: 350,
    doorPoint2y: 50,
    speechPosition: 350,
  }

  handleMouseEnter = () => {
    this.setState({
      doorPoint1x: 400,
      doorPoint1y: 400,
      doorPoint2x: 400,
      doorPoint2y: 100,
      speechPosition: 50,
    });
  }

  handleMouseLeave = () => {
    this.setState({
      doorPoint1x: 350,
      doorPoint1y: 350,
      doorPoint2x: 350,
      doorPoint2y: 50,
      speechPosition: 350,
    });
  }

  render() {
    return (
      <div
        className="image"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Motion
          defaultStyle={{
            doorPoint1x: 400,
            doorPoint1y: 400,
            doorPoint2x: 400,
            doorPoint2y: 100,
            speechPosition: 350,
          }}
          style={{
            doorPoint1x: spring(this.state.doorPoint1x),
            doorPoint1y: spring(this.state.doorPoint1y),
            doorPoint2x: spring(this.state.doorPoint2x),
            doorPoint2y: spring(this.state.doorPoint2y),
            speechPosition: spring(this.state.speechPosition),
          }}
        >
          {({ doorPoint1x, doorPoint1y, doorPoint2x, doorPoint2y, speechPosition }) => (
            <svg
              viewBox="0 0 600 400"
              width="600px"
              height="400px"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path
                d="M 0,350 350,350 350,50 550,50 550,350 600,350"
                stroke="var(--text-color)"
                strokeWidth="12px"
                fill="var(--background-color)"
              />

              {/* Speech bubble */}
              <g transform={`translate(${speechPosition},0)`} style={{ cursor: 'pointer' }}>
                <path
                  d="M 0,120 235,120 235,160 310,200 235,240 235,240 235,280 0,280 Z"
                  stroke="#758ef9"
                  strokeWidth="12px"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="var(--background-color)"
                />
                <text
                  x="35"
                  y="215"
                  fill="var(--text-color)"
                  fontSize="60"
                  textAnchor="center"
                  stroke="none"
                  style={{
                    fontFamily: '"Consolas", monospace',
                  }}
                >
                  #@*%!
                </text>
              </g>

              {/* Cover Speech Bubble */}
              <rect x="400" y="100" width="200" height="200" fill="var(--background-color)" />


              {/* Door */}
              <path
                d={`M ${doorPoint1x},${doorPoint1y} ${doorPoint2x},${doorPoint2y} 550,50 550,350 Z`}
                stroke="var(--text-color)"
                strokeWidth="12px"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="var(--background-color)"
              />
            </svg>
          )}
        </Motion>
      </div>
    );
  }
}

export default NormanDoors;
